import React from "react"
import PropTypes from "prop-types"
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import { wrapper } from '@support/AppContainer'
import withStyles from '@material-ui/core/styles/withStyles'

import styles from './styles'

class Index extends React.Component {
  render () {
    const {
      classes
    } = this.props

    return (
      <React.Fragment>
        <Paper className={classes.paper}>
          <Typography variant="h2">Style Guide</Typography>

          <Typography variant="h4">Buttons</Typography>

          <Typography variant="h5">Contained</Typography>
          <Button
            variant="contained"
            className={classes.button}>
            Default
          </Button>
          <Button variant="contained" color="primary" className={classes.button}>Primary</Button>
          <Button variant="contained" color="secondary" className={classes.button}>Secondary</Button>

          <Typography variant="h5">Outlined</Typography>
          <Button variant="outlined" className={classes.button}>Default</Button>
          <Button variant="outlined" color="primary" className={classes.button}>Primary</Button>
          <Button variant="outlined" color="secondary" className={classes.button}>Secondary</Button>
        </Paper>
      </React.Fragment>
    );
  }
}

export default wrapper(
  withStyles(styles)(Index)
)
