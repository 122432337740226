import PropTypes from 'prop-types'
import validator from 'validator'

export const validate = values => {
  const requiredFields = ['email', 'name']
  const errors = {}

  requiredFields.forEach(field => {
    if (!values[field]) errors[field] = 'Campo Obrigatório'
  })

  return errors
}
