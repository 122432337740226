import React from 'react'
import { Form as FinalForm } from 'react-final-form'
import PropTypes from 'prop-types'

import { validate } from '@models/document_type'
import { RailsForm } from '@support/RailsForm'
import { wrapper } from '@support/AppContainer'
import Form from './Form'

export const New = ({
  model,
  signatureTypes,
  dataTypes,
  signerTypes,
  roles,
  users,
  groups
}) => (
  <RailsForm
    component={FinalForm}
    action='create'
    url='/document_types'
    successUrl='/document_types'
    validate={validate}
    initialValues={model}
    render={(props) => (
      <Form
        {...props}
        title='Criar Tipo de Documento'
        signatureTypes={signatureTypes}
        dataTypes={dataTypes}
        signerTypes={signerTypes}
        roles={roles}
        users={users}
        groups={groups}
      />
    )}
  />
)

New.propTypes = {
  model: PropTypes.object,
  signatureTypes: PropTypes.array,
  dataTypes: PropTypes.array,
  groups: PropTypes.array
}

export default wrapper(New)
