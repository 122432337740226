import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Table from '@ui/Table'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

import { wrapper } from '@support/AppContainer'
import styles from '@styles/showPage'

export class Show extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      classes,
      model
    } = this.props

    return (
      <Paper
        className={classes.showPage__wrapper}
        elevation={0}
      >
        <header className={classes.showPage__header}>
          <Typography component='h2' variant='h5'>
            Importador de Documento
          </Typography>
        </header>

        <main className={classes.showPage__values}>
          <div className={classes.showPage__value}>
            <Typography
              color='textSecondary'
              component='h6'
              gutterBottom
              variant='h6'
            >
              Arquivo
            </Typography>

            <Typography paragraph variant='subtitle1' data-testid='filename'>
              {model.originalFilename}
            </Typography>
          </div>

          <div className={classes.showPage__value}>
            <Typography
              color='textSecondary'
              component='h6'
              gutterBottom
              variant='h6'
            >
              Importado Em / Por
            </Typography>

            <Typography paragraph variant='subtitle1' data-testid='importDetails'>
              {model.importDetails}
            </Typography>
          </div>

          <div className={classes.showPage__value}>
            <Typography
              color='textSecondary'
              component='h6'
              gutterBottom
              variant='h6'
            >
              Arquivos
            </Typography>

            <Typography variant='subtitle1' paragraph data-testid='total'>
              {model.importedFiles} importados / {model.failedFiles} falharam ({model.containedFiles} total)
            </Typography>
          </div>

          <div className={classes.showPage__value}>
            <Typography
              color='textSecondary'
              component='h6'
              gutterBottom
              variant='h6'
            >
              Situação
            </Typography>

            <Typography variant='subtitle1' paragraph data-testid='aasm_state'>
              {model.aasmState}
            </Typography>
          </div>

          {
            model.failuresLog &&
              <div className={classes.showPage__value}>
                <Typography
                  color='textSecondary'
                  component='h6'
                  gutterBottom
                  variant='h6'
                >
                  Log de Falhas
                </Typography>

                <Table
                  components={{
                    Container: props => <div>{props.children}</div>,
                  }}
                  columns={[
                    { title: 'Arquivo', field: 'filename' },
                    { title: 'Razão', field: 'reason' }
                  ]}
                  data={model.failuresLog || []}
                  options={{
                    showTitle: false
                  }}
                />
              </div>
          }
        </main>

        <footer className={classes.showPage__actions}>
          <Button
            className={classes.showPage__action}
            color='secondary'
            href={`/documents_bulk_imports`}
          >
            Voltar
          </Button>
        </footer>
      </Paper>
    )
  }
}

Show.propTypes = {
  model: PropTypes.object
}

export default wrapper(
  withStyles(styles)(Show)
)
