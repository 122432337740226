import React from 'react'
import TextField from '@material-ui/core/TextField'

const ControlledTextField = ({
  input,
  meta: { touched, error },
  ...textFieldProps
}) =>
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...input}
    {...textFieldProps}
  />

export default ControlledTextField
