import React from 'react'
import { FieldArray } from 'react-final-form-arrays'
import { Field } from 'react-final-form'
import Select from '@ui/Fields/Select'
import TextField from '@ui/Fields/TextField'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'

const renderSigner = (values, field, classes, signerType, users, roles) => {
  switch (signerType) {
    case 'role':
      return (
        <Field
          component={Select}
          className={classes.form__fieldWithError}
          InputLabelProps={{ shrink: true, required: true }}
          label='Assinante'
          name={`${field}.signer`}
          margin='normal'
          fields={{ id: 'id', name: 'id' }}
          data={roles.map((role) => ({id: role}))}
          fullWidth
          variant="outlined"
          required
        />
      )
    case 'meta':
      return (
        <Field
          component={Select}
          className={classes.form__fieldWithError}
          InputLabelProps={{ shrink: true, required: true }}
          label='Assinante'
          name={`${field}.signer`}
          margin='normal'
          fields={{ id: 'name', name: 'description' }}
          data={values.customFields.filter(d => d.type.match(/user|role/))}
          fullWidth
          variant="outlined"
          required
        />
      )
    case 'user':
      return (
        <Field
          component={Select}
          className={classes.form__fieldWithError}
          InputLabelProps={{ shrink: true, required: true }}
          label='Assinante'
          name={`${field}.signer`}
          margin='normal'
          fields={{ id: 'id', name: 'name' }}
          data={users}
          fullWidth
          variant="outlined"
          required
        />
      )
    default:
      return (
        <Field
          component={TextField}
          className={classes.form__fieldWithError}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          label='Assinante'
          margin='normal'
          disabled={true}
          fullWidth
          name={`${field}.signer`}
        />
      )
  }
}

const FormSignatureSteps = ({
  classes,
  signerTypes,
  values,
  roles,
  users,
  form: {
    mutators: { push }
  }
}) => (
  <>
    <Typography
      className={classes.dividerFullWidth}
      color="textSecondary"
      display="block"
      variant="caption"
    >
      Fluxo de Assinaturas
    </Typography>
    <Divider component="div" />
    <FieldArray name="signatureSteps">
      {({ fields }) => (
        <div>
          {fields.map((field, index) => (
            <div className={classes.form__field__wrapper} key={field}>
              <Grid container spacing={2}>
                <Grid item xs={1}>
                  <Field
                    component={TextField}
                    className={classes.form__fieldWithError}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    label='Ordem'
                    margin='normal'
                    name={`${field}.order`}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Field
                    component={Select}
                    className={classes.form__fieldWithError}
                    InputLabelProps={{ shrink: true, required: true }}
                    label='Tipo de Assinante'
                    name={`${field}.signerType`}
                    margin='normal'
                    fields={{ id: 'id', name: 'name' }}
                    data={signerTypes}
                    fullWidth
                    variant="outlined"
                    required
                  >
                  </Field>
                </Grid>
                <Grid item xs={2}>
                  { values.signatureSteps[index] && renderSigner(values, field, classes, values.signatureSteps[index].signerType, users, roles) }
                </Grid>
                <Grid item xs={2}>
                  <Field
                    component={TextField}
                    className={classes.form__fieldWithError}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    label='Assunto'
                    margin='normal'
                    fullWidth
                    name={`${field}.reason`}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Field
                    component={Select}
                    className={classes.form__fieldWithError}
                    InputLabelProps={{ shrink: true, required: true }}
                    label='Tipo de Certificado'
                    name={`${field}.icpBrasil`}
                    margin='normal'
                    fields={{ id: 'id', name: 'name' }}
                    data={[{id: false, name: 'Interno'}, {id: true, name: 'ICP-Brasil'}]}
                    fullWidth
                    variant="outlined"
                    required
                  >
                  </Field>
                </Grid>
                <Grid item xs={2}>
                  <Field
                    component={Select}
                    className={classes.form__fieldWithError}
                    InputLabelProps={{ shrink: true, required: true }}
                    label='Carimbo do Tempo?'
                    name={`${field}.timeStamp`}
                    margin='normal'
                    fields={{ id: 'id', name: 'name' }}
                    data={[{id: false, name: 'Não'}, {id: true, name: 'Sim'}]}
                    fullWidth
                    variant="outlined"
                    required
                  >
                  </Field>
                </Grid>
                <Grid item xs={12} md={1}>
                  <span
                    onClick={() => fields.remove(index)}
                    style={{
                      cursor: 'pointer',
                      position: 'relative',
                      top: 30
                    }}
                  >
                    ❌
                  </span>
                </Grid>
              </Grid>
            </div>
          ))}
        </div>
      )}
    </FieldArray>
    <Button
      variant='outlined'
      color='primary'
      className={classes.page__newResourceButton}
      onClick={() => push('signatureSteps', {
        order: '',
        signerType: '',
        signer: '',
        subject: '',
        timeStamp: ''
      })}
    >
      Nova Etapa de Assinatura
    </Button>
  </>
)

export default FormSignatureSteps
