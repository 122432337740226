import React from 'react'

import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'

const ControlledSelect = ({
  data,
  input,
  meta: { error, touched },
  selectBy,
  value,
  fields,
  ...textFieldProps
}) =>
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    select
    {...input}
    {...textFieldProps}
  >
    <MenuItem value=" " key=" ">
      Selecione
    </MenuItem>
    {
      data.map(datum => {
        const value = datum[fields.id] === undefined ? datum[fields.name] : datum[fields.id]

        return (
          <MenuItem value={value} key={value}>
            {datum[fields.name]}
          </MenuItem>
        )
      })
    }
  </TextField>

export default ControlledSelect
