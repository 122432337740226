import React from 'react'
import { Form as FinalForm } from 'react-final-form'
import PropTypes from 'prop-types'

import { validate } from '@models/custom_role'
import { RailsForm } from '@support/RailsForm'
import { wrapper } from '@support/AppContainer'
import Form from './Form'

export const Edit = ({
  model
}) => (
  <RailsForm
    component={FinalForm}
    action='update'
    url={`/custom_roles/${model.id}`}
    successUrl={`/custom_roles`}
    validate={validate}
    initialValues={model}
    render={(props) => (
      <Form
        {...props}
        title='Alterar Papel'
      />
    )}
  />
)

Edit.propTypes = {
  model: PropTypes.object
}

export default wrapper(Edit)
