const drawerWidth = 250

export default theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  paper: {
    top: 64,
    borderTop: 'solid 1px #e1e1e1',
    '@media(max-width: 599px)': {
      top: 56
    },
    '@media(max-width: 376px)': {
      top: 56
    }
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7.5),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7.5),
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 6px',
    ...theme.mixins.toolbar,
  },
  listIcon: {
    margin: 0,
    padding: 3,
    minWidth: theme.spacing(5)
  }
})
