import React from 'react'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import { Field } from 'react-final-form'

import TextField from '@ui/Fields/TextField'
import styles from '@theme/styles/forms'

const Form = ({
  handleSubmit,
  classes,
  title
}) => {
  return (
    <form className={classes.form} onSubmit={handleSubmit} data-testid="document-type-form">
      <Paper className={classes.form__wrapper} elevation={0}>
        <header className={classes.form__header}>
          <Typography component='h2' variant='h5'>
            {title}
          </Typography>
        </header>
        <main className={classes.form__fields}>
          <div className={classes.form__field__wrapper}>
            <Field
              component={TextField}
              className={classes.form__fieldWithError}
              margin='normal'
              fullWidth
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              label='Código'
              name='code'
              required
            />
          </div>
          <div className={classes.form__field__wrapper}>
            <Field
              component={TextField}
              className={classes.form__fieldWithError}
              fullWidth
              InputLabelProps={{ shrink: true }}
              label='Descrição'
              margin='normal'
              name='description'
              variant="outlined"
              required
            />
          </div>
        </main>
        <footer className={classes.form__actions}>
          <Button
            className={classes.form__action}
            color='primary'
            type='submit'
            variant='contained'
          >
            {title}
          </Button>

          <Button
            className={classes.form__action}
            color='secondary'
            href={`/custom_roles`}
          >
            Voltar
          </Button>
        </footer>
      </Paper>
    </form>
  )
}

export default withStyles(styles)(Form)
