import React from 'react'

import withStyles from '@material-ui/core/styles/withStyles'

import OrganizationsMenu from './OrganizationsMenu'
import styles from '../styles'

const RightGroupMenu = ({ adminMenus, isAdmin, products, notifications, selectedOrganization, organizations, user, classes }) => (
  <div className={classes.container}>
    <OrganizationsMenu selectedOrganization={selectedOrganization} organizations={organizations} user={user} isAdmin={isAdmin}/>
  </div>
)

export default withStyles(styles)(RightGroupMenu);
