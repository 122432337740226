export default theme => ({
  buttons: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  page__wrapper: {
    backgroundColor: 'transparent',
    margin: `${theme.spacing(1)}px auto 0`,
    padding: theme.spacing(4),
  },

  page__header: {
    marginBottom: theme.spacing(4)
  },

  page__newResourceButton: {
    marginBottom: theme.spacing(4)
  },

  page__gridItem: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
})
