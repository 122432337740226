import React from 'react'
import MaterialTable from 'material-table'

const Table = ({ options, localization, ...props }) =>
  <MaterialTable
    {...props}
    options={{
      showEmptyDataSourceMessage: true,
      actionsColumnIndex: -1,
      ...options
    }}
    localization={{
      ...localization,
      header: {
        actions: 'Ações',
        ...localization.header
      },
      body: {
        emptyDataSourceMessage: 'Nenhum dado a ser exibido',
        ...localization.body
      },
      pagination: {
        labelRowsSelect: 'linhas',
        labelRowsPerPage: 'Linhas por página:',
        firstAriaLabel: 'Primeira página',
        firstTooltip: 'Primeira página',
        previousAriaLabel: 'Página anterior',
        previousTooltip: 'Página anterior',
        nextAriaLabel: 'Próxima página',
        nextTooltip: 'Próxima página',
        lastAriaLabel: 'Última página',
        lastTooltip: 'Última página',
        labelDisplayedRows: '{from}-{to} de {count}',
        pageSize: 25,
        pageSizeOptions: [1, 25, 50, 100],
        emptyRowsWhenPaging: false,
        ...localization.pagination
      },
      toolbar: {
        nRowsSelected: '{0} linha(s) selecionada',
        searchPlaceholder: 'Pesquisar',
        searchTooltip: 'Pesquisar',
        ...localization.toolbar
      }
    }}
  />

Table.defaultProps = {
  localization: {}
}

export default Table
