export default theme => ({
  showPage__action: {
    '&:not(:last-child)': {
      marginLeft: theme.spacing(2)
    }
  },

  showPage__actions: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-start',
    marginTop: theme.spacing(4)
  },

  showPage__header: {
    marginBottom: theme.spacing(4)
  },

  showPage__value: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(3)
    }
  },

  showPage__wrapper: {
    backgroundColor: 'transparent',
    margin: `${theme.spacing(5)}px auto`,
    padding: theme.spacing(4)
  }
})
