import React, { Component } from "react"
import Table from '@ui/Table'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import PreviewDocument from '@ui/PreviewDocument'
import { wrapper } from '@support/AppContainer'
import withStyles from '@material-ui/core/styles/withStyles'
import httpService from '@services/httpService'
import styles from '@styles/indexPage'
import { withSnackbar } from 'notistack'

class Index extends Component {
  constructor(props) {
    super(props)

    this.tableRef = React.createRef()
  }

  render () {
    const { classes } = this.props

    return (
      <Paper className={classes.page__wrapper} elevation={0}>
        <header className={classes.page__header}>
          <Typography component='h1' variant='h4'>
            Documentos
          </Typography>
        </header>
        <Table
          components={{
            Container: props => <div>{props.children}</div>,
          }}
          detailPanel={rowData => {
            return ( <pre>{ JSON.stringify(rowData.metadata, null, '\t') }</pre> )
          }}
          columns={[
            { title: 'Data', field: 'updatedAt' },
            { title: 'Descrição', field: 'documentType.description' },
            { title: 'Arquivo', field: 'originalFilename' },
            {
              render: rowData =>
                <Tooltip title="Baixar">
                  <IconButton
                    color="inherit"
                    onClick={(event) => window.open(rowData.fileUrl)}>
                    <DownloadIcon/>
                  </IconButton>
                </Tooltip>
            }
          ]}
          tableRef={this.tableRef}
          data={
            query =>
              new Promise((resolve, reject) => {
                httpService.get(`/documents.json?search=${query.search}&per_page=${query.pageSize}&page=${query.page + 1}`)
                  .then((result) => result.data)
                  .then((result) => {
                    resolve({
                      data: result.data,
                      page: query.page,
                      totalCount: result.total
                    })
                  })
              })
          }
          actions={[
            {
              icon: 'refresh',
              tooltip: 'Atualizar',
              isFreeAction: true,
              onClick: () => this.tableRef.current && this.tableRef.current.onQueryChange(),
            }
          ]}
          options={{
            showTitle: false,
            selection: true,
            pageSize: 25,
            pageSizeOptions: [25, 50, 100, 500, 1000],
            emptyRowsWhenPaging: false,
            debounceInterval: 500,
            sorting: false
          }}
        />
      </Paper>
    )
  }
}

export default wrapper(
  withSnackbar(
    withStyles(styles)(Index)
  )
)
