import React from 'react'
import { Form as FinalForm } from 'react-final-form'
import PropTypes from 'prop-types'

import { validate } from '@models/certificate'
import { RailsForm } from '@support/RailsForm'
import { wrapper } from '@support/AppContainer'
import Form from './Form'

export const New = ({
  certificate,
  certificateTypes
}) => (
  <RailsForm
    component={FinalForm}
    action='create'
    url='/certificates'
    successUrl='/certificates'
    validate={validate}
    initialValues={certificate}
    render={(props) => (
      <Form
        {...props}
        title='Adicionar Certificado'
        certificateTypes={certificateTypes}
      />
    )}
  />
)

New.propTypes = {
  model: PropTypes.object,
  certificateTypes: PropTypes.array
}

export default wrapper(New)
