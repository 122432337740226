import httpService from '@services/httpService'
import EduSigner from 'edu-signer-js'

const signer = new EduSigner({
  licenseKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJjZXJ0aWZpY2FlZHUuY29tLmJyIiwiZXhwIjozMjUwMzYwMDgwMH0.vqfFSV4mqs5aSggT59iiIF2tSkQvrIFq5FKMy6WORJk'
})

export const pki = signer.PKI

export let errors = []

export const signDocument = (row, certificate, password) => {
  return new Promise((resolve, reject) => {
    let jsessionid = ''
    if (certificate.certificateType === 'A1') {
      signA1(row.id, certificate, password).then((result) => {
        resolve(result)
      }).catch((error) => {
        errors.push([row, error])
        resolve()
      })
    } else {
      preSign(row.id, certificate).then((result)=> {
        jsessionid = result.data.JSESSIONID
        return signData(result.data.data, certificate, password)
      }).then((data) => {
        return postSign(row.id, data, jsessionid)
      }).then((result) => {
        resolve(result)
      }).catch((error) => {
        errors.push([row, error])
        resolve()
      })
    }
  })
}

const signA1 = (id, certificate, password) => {
  return httpService.post(
    `/signatures/${id}/sign_a1`,
    { certificate_id: certificate.id, password: password }
  )
}

const preSign = (id, certificate) => {
  return httpService.post(
    `/signatures/${id}/pre_sign`,
    { certificate_id: certificate.id }
  )
}

const postSign = (id, data, jsessionid) => {
  return httpService.post(
    `/signatures/${id}/post_sign`,
    { data: data, jsessionid: jsessionid }
  )
}

const signData = (data, certificate, password) => {
  return new Promise((resolve, reject) => {
    if (certificate.certificateType === 'A1') {
      signDataA1(data, certificate, password).then((result) => {
        resolve(result.data.data)
      }).catch((error) => {
        reject(error)
      })
    } else {
      signDataA3(data, certificate).then((result) => {
        if(result.error)
          reject(result.error)
        else
          resolve(result.DataSigned)
      }).catch((error) => {
        reject(error)
      })
    }
  })
}

const signDataA1 = (data, certificate, password) => {
  return httpService.post(
    `/certificates/${certificate.id}/sign`,
    { data: data, password: btoa(password) }
  )
}

const signDataA3 = (data, certificate) => {
  return pki.signData(certificate.fingerprint, data)
}
