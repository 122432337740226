import React from 'react'

import Button from '@material-ui/core/Button'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from '../styles'

const CurrentUser = ({ classes, user }) => (
  <ListItem>
   <div className={classes.organizations__icon__selected__wrapper}>
      <Typography className={classes.organizations__icon__selected}>
        {user.name[0]}
      </Typography>
   </div>
    <div>
      <ListItemText primary={user.name} data-testid="selectedOrganizationName" />
      <Typography variant="caption" data-testid="selectedOrganizationLabel">
        {user.email}
      </Typography>
    </div>
  </ListItem>
)

export default withStyles(styles)(CurrentUser)
