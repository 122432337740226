import React from 'react'
import { Form as FinalForm } from 'react-final-form'
import PropTypes from 'prop-types'

import { validate } from '@models/document_group'
import { RailsForm } from '@support/RailsForm'
import { wrapper } from '@support/AppContainer'
import Form from './Form'

export const New = ({
  model,
  signatureTypes,
  dataTypes,
  signerTypes,
  roles,
  users
}) => {
  return (
    <RailsForm
      component={FinalForm}
      action='create'
      url='/document_groups'
      successUrl='/document_groups'
      validate={validate}
      initialValues={model}
      render={(props) => (
        <Form
          {...props}
          title='Criar Grupo de Documento'
          signatureTypes={signatureTypes}
          dataTypes={dataTypes}
          signerTypes={signerTypes}
          roles={roles}
          users={users}
        />
      )}
    />
  )
}

New.propTypes = {
  model: PropTypes.object
}

export default wrapper(New)
