import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import Table from '@ui/Table'

import httpService from '@services/httpService'
import { wrapper } from '@support/AppContainer'
import DeleteDialog from '@ui/DeleteDialog'
import styles from '@styles/indexPage'

class Index extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isDialogOpen: false,
      id: null
    }

    this.closeDialog = this.closeDialog.bind(this)
  }

  closeDialog() {
    this.setState({ isDialogOpen: false })
  }

  render() {
    const { model, classes } = this.props
    const { isDialogOpen, id } = this.state

    return (
      <Paper className={classes.page__wrapper} elevation={0}>
        <header className={classes.page__header}>
          <Typography component='h1' variant='h4'>
            Usuários
          </Typography>
        </header>
        <div className={classes.buttons}>
          <Button
            variant='outlined'
            color='primary'
            className={classes.page__newResourceButton}
            href='/users/new'
          >
            Novo
          </Button>
          <Button
            variant='outlined'
            color='secondary'
            className={classes.page__newResourceButton}
            href='/users.csv'
          >
            Exportar CSV
          </Button>        
        </div>

        <Table
          components={{
            Container: props => <div>{props.children}</div>,
          }}
          columns={[
            { title: 'Email', field: 'email' },
            { title: 'Nome', field: 'name' },
            {
              title: 'Papeis',
              field: 'roles',
              render: rowData => <>{ rowData.roles.map(e => (<Chip label={e} key={e} />)) }</>
            }
          ]}
          data={model}
          actions={[
            rowData => ({
              icon: 'remove_red_eye',
              iconProps: { 'data-testid': `show-${rowData.id}` },
              tooltip: 'Visualizar',
              onClick: (event, rowData) => {
                Turbolinks.visit(`/users/${rowData.id}`)
              }
            }),
            rowData => ({
              icon: 'create',
              iconProps: { 'data-testid': `edit-${rowData.id}` },
              tooltip: 'Editar',
              onClick: (event, rowData) => {
                Turbolinks.visit(`/users/${rowData.id}/edit`)
              }
            }),
            rowData => ({
              icon: 'delete',
              iconProps: { 'data-testid': `delete-${rowData.id}` },
              tooltip: 'Excluir',
              onClick: (event, rowData) => {
                this.setState({ isDialogOpen: true, id: rowData.id })
              }
            })
          ]}
          options={{
            showTitle: false,
            pageSize: 25,
            pageSizeOptions: [25, 50, 100],
            emptyRowsWhenPaging: false
          }}
        />

        <DeleteDialog
          dialogTitle={'Remover Usuário'}
          dialogText={'Deseja remover este usuário?'}
          successUrl='/users'
          deleteUrl={`/users/${id}.json`}
          onCancel={this.closeDialog}
          onClose={this.closeDialog}
          open={isDialogOpen}
        />
      </Paper>
    )
  }
}

Index.propTypes = {
  model: PropTypes.array
}

export default wrapper(
  withStyles(styles)(Index)
)
