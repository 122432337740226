import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Input from '@material-ui/core/Input';
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import withMobileDialog from '@material-ui/core/withMobileDialog'

class PasswordDialog extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { onClose, open, password, certificate, handleOk, handleChange } = this.props

    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Informe o PIN do seu certificado</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Certificado: {certificate && certificate.subject}
          </DialogContentText>
          <Input
            type="password"
            value={password}
            inputProps={{
              'autoComplete': 'new-password'
            }}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            onClick={onClose}
          >
            Cancelar
          </Button>
          <Button
            color='primary'
            variant='contained'
            onClick={handleOk}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withMobileDialog()(PasswordDialog)
