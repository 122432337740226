import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import Table from '@ui/Table'

import httpService from '@services/httpService'
import { wrapper } from '@support/AppContainer'
import DeleteDialog from '@ui/DeleteDialog'
import styles from '@styles/indexPage'

class Index extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isDialogOpen: false,
      id: null
    }

    this.closeDialog = this.closeDialog.bind(this)
  }

  closeDialog() {
    this.setState({ isDialogOpen: false })
  }

  render() {
    const { model, classes } = this.props
    const { isDialogOpen, id } = this.state

    return (
      <Paper className={classes.page__wrapper} elevation={0}>
        <header className={classes.page__header}>
          <Typography component='h1' variant='h4'>
            Tipos de Documentos
          </Typography>
        </header>

        <Button
          variant='outlined'
          color='primary'
          className={classes.page__newResourceButton}
          href='/document_types/new'
        >
          Novo
        </Button>

        <Table
          components={{
            Container: props => <div>{props.children}</div>,
          }}
          columns={[
            { title: 'Código', field: 'code' },
            { title: 'Descrição', field: 'description' },
            { title: 'Grupo', field: 'groupDescription' }
          ]}
          data={model}
          actions={[
            rowData => ({
              icon: 'remove_red_eye',
              iconProps: { 'data-testid': `show-${rowData.id}` },
              tooltip: 'Visualizar',
              onClick: (event, rowData) => {
                Turbolinks.visit(`/document_types/${rowData.id}`)
              }
            }),
            rowData => ({
              icon: 'create',
              iconProps: { 'data-testid': `edit-${rowData.id}` },
              tooltip: 'Editar',
              onClick: (event, rowData) => {
                Turbolinks.visit(`/document_types/${rowData.id}/edit`)
              }
            }),
            rowData => ({
              icon: 'delete',
              iconProps: { 'data-testid': `delete-${rowData.id}` },
              tooltip: 'Excluir',
              onClick: (event, rowData) => {
                this.setState({ isDialogOpen: true, id: rowData.id })
              }
            })
          ]}
          options={{
            showTitle: false,
            pageSize: 25,
            pageSizeOptions: [25, 50, 100],
            emptyRowsWhenPaging: false
          }}
        />

        <DeleteDialog
          dialogTitle={'Remover Tipo de Documento'}
          dialogText={'Deseja remover este Tipo de Documento?'}
          successUrl='/document_types'
          deleteUrl={`/document_types/${id}.json`}
          onCancel={this.closeDialog}
          onClose={this.closeDialog}
          open={isDialogOpen}
        />
      </Paper>
    )
  }
}

Index.propTypes = {
  model: PropTypes.array
}

export default wrapper(
  withStyles(styles)(Index)
)
