import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Grid from '@material-ui/core/Grid'
import Chip from '@material-ui/core/Chip'
import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

import VpnKeyIcon from '@material-ui/icons/VpnKey'
import DeleteIcon from '@material-ui/icons/Delete'
import httpService from '@services/httpService'

import { wrapper } from '@support/AppContainer'
import DeleteDialog from '@ui/DeleteDialog'
import styles from '@styles/indexPage'

class Index extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isDialogOpen: false,
      id: null
    }

    this.closeDialog = this.closeDialog.bind(this)
  }

  closeDialog() {
    this.setState({ isDialogOpen: false })
  }

  render() {
    const { certificates, classes } = this.props
    const { isDialogOpen, id } = this.state

    const aStyle = {
      color: '#FFF',
      textDecoration: 'underline',
      fontWeight: 'bold'
    };

    return (
      <>
        <Box p={2} bgcolor="secondary.main" color="secondary.contrastText">
          Caso você utilize certificado A3, <a href="/Setup.msi" style={aStyle}>click aqui</a> e instale o plugin.
        </Box>

        <Paper className={classes.page__wrapper} elevation={0}>
          <header className={classes.page__header}>
            <Typography component='h1' variant='h4'>
              Meus Certificados
            </Typography>
          </header>

          <Button
            variant='outlined'
            color='primary'
            className={classes.page__newResourceButton}
            href='/certificates/new'
          >
            Novo
          </Button>

          {
            certificates.map((certificate) => (
              <Paper className={classes.page__gridItem} key={certificate.id}>
                <Grid container justify="center" spacing={2}>
                  <Grid item>
                    <VpnKeyIcon style={ certificate.icp_brasil ? { color: 'green' } : { color: 'gray' } }/>
                    <br />
                    <Chip size="small" label={certificate.certificateType} className={classes.chip} />
                    <br />
                    <Tooltip title="Remover Certificado">
                      <IconButton
                        aria-label="delete"
                        onClick={() => this.setState({ isDialogOpen: true, id: certificate.id })}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography noWrap variant="h6">{certificate.subject}</Typography>
                    <Typography noWrap>Emitido por: {certificate.issuer}</Typography>
                    <Typography noWrap>Válido até: {certificate.expiresAt}</Typography>
                    <Divider />
                    <Typography noWrap>
                      {certificate.fingerprint}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            ))
          }

          <DeleteDialog
            dialogTitle={'Remover Certificado'}
            dialogText={'Deseja remover este certificado?'}
            successUrl='/certificates'
            deleteUrl={`/certificates/${id}.json`}
            onCancel={this.closeDialog}
            onClose={this.closeDialog}
            open={isDialogOpen}
          />
        </Paper>
      </>
    )
  }
}

Index.propTypes = {
  model: PropTypes.array
}

export default wrapper(
  withStyles(styles)(Index)
)
