import React from 'react'
import httpService from '@services/httpService'
import snakeCaseKeys from 'snakecase-keys'
import arrayMutators from 'final-form-arrays'

const actions = {
  create: 'POST',
  update: 'PUT'
}

const RailsForm = ({
  component,
  render,
  method,
  url,
  validate,
  action,
  initialValues,
  successUrl,
  handleSubmit
}) => {
  const defaultOnSubmit = async values => {
    const form_method = !method ? actions[action] : method

    if (!form_method) {
      console.error('method or action not defined')
      throw 'method or action not defined'
    }

    try {
      const response = await httpService({
        method: form_method,
        url: `${url}.json`,
        data: snakeCaseKeys(values)
      })

      Turbolinks.visit(successUrl ? successUrl : `${url}/${response.data.id}`)
    }
    catch (error) {
      console.error(error)
    }
  }

  const onSubmit = handleSubmit || defaultOnSubmit

  return React.createElement(component, {
    onSubmit,
    validate,
    initialValues,
    mutators: {
      ...arrayMutators
    },
    render: ({
      handleSubmit,
      reset,
      submitting,
      pristine,
      values,
      form
    }) => render({
      handleSubmit,
      reset,
      submitting,
      pristine,
      values,
      form
    })
  })
}

export { RailsForm };
