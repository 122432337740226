import React from 'react'
import classNames from 'classnames'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import Link from '@material-ui/core/Link'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MenuIcon from '@material-ui/icons/Menu'
import IconButton from '@material-ui/core/IconButton'

import HomeIcon from '@material-ui/icons/Home'
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'
import BackupIcon from '@material-ui/icons/Backup'
import AssignmentIcon from '@material-ui/icons/Assignment'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import DocumentIcon from '@material-ui/icons/FindInPage'

import withStyles from '@material-ui/core/styles/withStyles'
import { wrapper } from '@support/AppContainer'
import styles from './styles'

class SideBar extends React.Component {
  state = {
    open: false
  }

  handleMenuOpen = () => {
    this.setState({ open: !this.state.open })
  }

  displayMenuIcon = (name) => {
    const icons = {
      home: HomeIcon,
      document: DocumentIcon,
      signature_logs: AssignmentLateIcon,
      document_types: AssignmentIcon,
      document_groups: LibraryBooksIcon,
      documents_bulk_imports: BackupIcon,
      users: SupervisorAccountIcon,
      custom_roles: AssignmentIndIcon,
      certificates: VpnKeyIcon
    }

    const IconName = icons[name]
    return <IconName />
  }

  render() {
    const { classes, features } = this.props
    const { open } = this.state

    return (
      <Drawer
        variant="permanent"
        className={classNames(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: classNames(classes.paper, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        open={open}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={this.handleMenuOpen}>
            {open ? <ChevronLeftIcon /> : <MenuIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          {
            features.map((feature) => (
              <ListItem
                button
                key={feature.text}
                title={feature.text}
                component={Link}
                onClick={() => Turbolinks.visit(`/${feature.path}`)}
              >
                <ListItemIcon>
                  {this.displayMenuIcon(feature.name)}
                </ListItemIcon>
                <ListItemText primary={feature.text} />
              </ListItem>
            ))
          }
        </List>
      </Drawer>
    )
  }
}

export default wrapper(
  withStyles(styles)(SideBar)
)
