import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import Table from '@ui/Table'

import { wrapper } from '@support/AppContainer'
import styles from '@styles/indexPage'

class Index extends Component {
  constructor(props) {
    super(props)

    this.state = {
      id: null
    }
  }

  render() {
    const { model, classes } = this.props
    const { id } = this.state

    return (
      <Paper className={classes.page__wrapper} elevation={0}>
        <header className={classes.page__header}>
          <Typography component='h1' variant='h4'>
            Importador de Documentos
          </Typography>
        </header>

        <Button
          variant='outlined'
          color='primary'
          className={classes.page__newResourceButton}
          href='/documents_bulk_imports/new'
        >
          Importar
        </Button>

        <Table
          components={{
            Container: props => <div>{props.children}</div>,
          }}
          columns={[
            { title: 'Arquivo', field: 'originalFilename' },
            { title: 'Importado Em / Por', field: 'importDetails' },
            { title: 'Total', field: 'containedFiles' },
            { title: 'Importados', field: 'importedFiles' },
            { title: 'Falhos', field: 'failedFiles' },
            { title: 'Situação', field: 'aasmState' },
          ]}
          data={model}
          actions={[
            rowData => ({
              icon: 'remove_red_eye',
              iconProps: { 'data-testid': `show-${rowData.id}` },
              tooltip: 'Visualizar',
              onClick: (event, rowData) => {
                Turbolinks.visit(`/documents_bulk_imports/${rowData.id}`)
              }
            })
          ]}
          options={{
            showTitle: false,
            pageSize: 25,
            pageSizeOptions: [25, 50, 100],
            emptyRowsWhenPaging: false
          }}
        />
      </Paper>
    )
  }
}

Index.propTypes = {
  model: PropTypes.array
}

export default wrapper(
  withStyles(styles)(Index)
)
