import React from 'react'
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './styles'

const NativeSelect = ({
  data,
  classes,
  name,
  label,
  handleChange,
  fields,
  value,
  ...selectProps
}) =>
<FormControl variant="outlined" className={classes.formControl}>
  <InputLabel htmlFor={name} shrink>{label}</InputLabel>
  <Select
    native
    value={value}
    onChange={handleChange}
    {...selectProps}
    inputProps={{
      name: name,
      id: name,
    }}
  >
    {
      data.map(datum => {
        const value = datum[fields.id] === undefined ? datum[fields.name] : datum[fields.id]

        return (
          <option value={value} key={value}>
            {datum[fields.name]}
          </option>
        )
      })
    }
  </Select>
</FormControl>

export default withStyles(styles)(NativeSelect)
