export default theme => ({
  organizations__item: {
    background: theme.palette.lightGray,
    display: 'flex',
  },
  organizationsMenu__footer: {
    justifyContent: 'space-between'
  },
  organizationsMenu__list: {
    padding: 0,
    minWidth: 350,
  },
  organizationsMenu__button: {
    textTransform: 'none',
    fontSize: 13,
    minHeight: 30,
    paddingLeft: 10,
    paddingRight: 10,
  },
  organizations__icon: {
    color: "blue",
    width: 18,
    height: 18,
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 500
  },
  organizations__icon__selected: {
    color: 'white',
    width: 26,
    height: 27,
    display: 'flex',
    justifyContent: 'center',
    fontSize: 20,
    fontWeight: 600
  },
  organizations__icon__selected__wrapper: {
    background: theme.palette.primary.light,
    borderRadius: 100,
    marginRight: 20,
    padding: 20,
    fontSize: 25,
  },
  organizations__icon__list__wrapper: {
    padding: 10,
    background: theme.palette.primary.light,
    borderRadius: 100,
    marginRight: 20,
  },
  organizations__edit: {
    textTransform: 'none',
    paddingLeft: 10,
    paddingRight: 10,
    marginTop: 5,
    marginBottom: 5,
    fontSize: 13,
    fontWeight: 600,
    minHeight: 30,
  },
})
