import React from 'react'
import { wrapper } from '@support/AppContainer'

import withStyles from '@material-ui/core/styles/withStyles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import RightGroupMenu from './RightGroupMenu'

import Logo from './Logo'
import styles from './styles'

class NavBar extends React.Component {
  render() {
    const {
      organizations,
      selectedOrganization,
      user,
      classes,
    } = this.props

    const organizationList = organizations.filter(organization => organization.id !== selectedOrganization.id)

    return (
      <div className={classes.wrapper}>
        <AppBar elevation={1} className={classes.container}>
          <Toolbar  className={classes.menu}>
            <a
              href='/'
              className={classes.menu__logoLink}
            >
              <Logo organization={selectedOrganization} />
            </a>
            <RightGroupMenu
              organizations={organizationList}
              selectedOrganization={selectedOrganization}
              user={user}
              notifications={[]}
              profiles={[]}
            />
          </Toolbar>
        </AppBar>
      </div>
    )
  }
}

export default wrapper(
  withStyles(styles)(NavBar)
)
