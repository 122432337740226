import React from 'react'

import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import httpService from '@services/httpService'

import styles from '../styles'

const setCurrentOrganization = id => {
  httpService.put(`/sessions/organization/${id}`)
    .then(() => location.reload())
    .catch((error) => console.error(error))
}

const OrganizationsList = ({ organizations, classes }) =>
  organizations.map((organization, index) =>
    <div key={`itemListOrg-${organization.id}`}>
      <ListItem
        button
        onClick={() => setCurrentOrganization(organization.id)}
        key={`menu-list-${index}`}
        className={classes.organizations__item}
        data-testid={`menu-list-${organization.id}`}
      >
        <div className={classes.organizations__icon__list__wrapper}>
          <Typography className={classes.organizations__icon}>
            {organization.name[0]}
          </Typography>
        </div>
        <div>
          <ListItemText primary={organization.name} />
          <Typography variant="caption">
            {organization.email}
          </Typography>
        </div>
      </ListItem>
      <Divider />
    </div>
  )

export default withStyles(styles)(OrganizationsList)
