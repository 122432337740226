import React from 'react'
import { Form as FinalForm } from 'react-final-form'
import PropTypes from 'prop-types'

import { validate } from '@models/documents_bulk_import'
import { RailsForm } from '@support/RailsForm'
import { wrapper } from '@support/AppContainer'
import Form from './Form'

export const New = ({
  model, documentTypes
}) => (
  <RailsForm
    component={FinalForm}
    action='create'
    url='/documents_bulk_imports'
    successUrl='/documents_bulk_imports'
    validate={validate}
    initialValues={model}
    render={(props) => (
      <Form
        {...props}
        title='Importar Documentos'
        documentTypes={documentTypes}
      />
    )}
  />
)

New.propTypes = {
  model: PropTypes.object,
  certificateTypes: PropTypes.array
}

export default wrapper(New)
