export default theme => ({
  form: {
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  form__wrapper: {
    backgroundColor: 'transparent',
    margin: `${theme.spacing(5)}px auto`,
    padding: theme.spacing(4)
  },
  form__header: {
    marginBottom: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between'
  },
  form__fields: {
    display: 'flex',
    flexDirection: 'column',
    margin: '10px'
  },
  form__field__wrapper: {
    minHeight: theme.spacing(12.5)
  },
  form__fieldWithError: {
    minHeight: theme.spacing(6)
  },
  form__actions: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: theme.spacing(4)
  },
  form__action: {
    '&:not(:last-child)': {
      marginLeft: theme.spacing(2)
    }
  },
  form__dropzone: {
    margin: '5px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: theme.palette.black,
    backgroundColor: theme.palette.gray.light,
    padding: '10px 16px',
    fontSize: '0.875rem',
    minWidth: '64px',
    borderRadius: '4px',
    fontWeight: 500,
    textTransform: 'uppercase',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    cursor: 'pointer',
    outline: 'none',
    '&:hover': {
      backgroundColor: theme.palette.gray.main,
    }
  },
  form__dropzone_onDrag: {
    backgroundColor: theme.palette.white,
    color: theme.palette.primary.dark,
    border: `2px dashed ${theme.palette.primary.dark}`,
    margin: '4px 0'
  },
  form__dropzone_onDragReject: {
    backgroundColor: theme.palette.white,
    color: theme.palette.darkRed,
    border: `2px dashed ${theme.palette.darkRed}`,
    margin: '4px 0'
  },
  form__dropzone_selectedFile: {
    color: 'inherit',
    margin: '5px 0',
    textTransform: 'none'
  },
  form__currentIcon_wrapper: {
    margin: '15px 0'
  },
  form__currentIcon: {
    padding: '10px 0'
  }
})
