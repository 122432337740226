import React from 'react'
import { FieldArray } from 'react-final-form-arrays'
import { Field } from 'react-final-form'

import Select from '@ui/Fields/Select'
import TextField from '@ui/Fields/TextField'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'

const FormCustomFields = ({
  classes,
  dataTypes,
  form: {
    mutators: { push }
  }
}) => (
  <>
    <Typography
      className={classes.dividerFullWidth}
      color="textSecondary"
      display="block"
      variant="caption"
    >
      Campos Personalizados
    </Typography>
    <Divider component="div" />
    <FieldArray name="customFields">
      {({ fields }) => (
        <div>
          {fields.map((field, index) => (
            <div className={classes.form__field__wrapper} key={field}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Field
                    component={TextField}
                    className={classes.form__fieldWithError}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    label='Nome do Campo'
                    margin='normal'
                    fullWidth
                    name={`${field}.name`}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    component={TextField}
                    className={classes.form__fieldWithError}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    label='Descrição do Campo'
                    margin='normal'
                    fullWidth
                    name={`${field}.description`}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    component={Select}
                    className={classes.form__fieldWithError}
                    InputLabelProps={{ shrink: true, required: true }}
                    label='Tipo de Dados'
                    name={`${field}.type`}
                    margin='normal'
                    fields={{ id: 'id', name: 'name' }}
                    data={dataTypes}
                    fullWidth
                    variant="outlined"
                    required
                  />
                </Grid>
                <Grid item xs={2}>
                  <Field
                    component={TextField}
                    className={classes.form__fieldWithError}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    label='Valor padrão'
                    margin='normal'
                    fullWidth
                    name={`${field}.value`}
                  />
                </Grid>
                <Grid item xs={1}>
                  <span
                    onClick={() => fields.remove(index)}
                    style={{
                      cursor: 'pointer',
                      position: 'relative',
                      top: 30
                    }}
                  >
                    ❌
                  </span>
                </Grid>
              </Grid>
            </div>
          ))}
        </div>
      )}
    </FieldArray>
    <Button
      variant='outlined'
      color='primary'
      className={classes.page__newResourceButton}
      onClick={() => push('customFields', {
        name: '',
        description: '',
        type: '',
        value: ''
      })}
    >
      Novo campo personalizado
    </Button>
  </>
)

export default FormCustomFields
