import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack'
import theme from '../theme/index'

class AppContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true
    }

    this.setLoading = this.setLoading.bind(this)
  }

  componentWillMount() {
    this.setLoading(true)
  }

  componentDidMount() {
    this.setLoading(false)
  }

  componentWillUnmount() {
    this.setLoading(true)
  }

  setLoading(status) {
    this.setState({ loading: status })
  }

  render() {
    const { children } = this.props

    return (
      <MuiThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          {
            React.cloneElement(
              children,
              { setLoading: this.setLoading }
            )
          }
        </SnackbarProvider>
      </MuiThemeProvider>
    )
  }
}

const wrapper = (component) =>
  ({ ...props }) => {
    const Component = React.createElement(component, { ...props })

    return (
      <AppContainer>
        {Component}
      </AppContainer>
    )
  }

export { wrapper }
