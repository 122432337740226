import React from 'react'
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import { Field } from 'react-final-form'
import Select from '@ui/Fields/Select'
import TextField from '@ui/Fields/TextField'
import styles from '@theme/styles/forms'
import FormCustomFields from '../document_types/FormCustomFields'
import FormSignatureSteps from '../document_types/FormSignatureSteps'

const Form = ({
  handleSubmit,
  classes,
  title,
  values,
  signatureTypes,
  dataTypes,
  signerTypes,
  roles,
  users,
  form
}) => {
  return (
    <form className={classes.form} onSubmit={handleSubmit} data-testid="document-group-form">
      <Paper className={classes.form__wrapper} elevation={0}>
        <header className={classes.form__header}>
          <Typography component='h2' variant='h5'>
            {title}
          </Typography>
        </header>
        <main className={classes.form__fields}>
          <div className={classes.form__field__wrapper}>
            <Field
              component={TextField}
              className={classes.form__fieldWithError}
              margin='normal'
              fullWidth
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              label='Código de Classificação'
              name='classification'
              required
            />
          </div>
          <div className={classes.form__field__wrapper}>
            <Field
              component={TextField}
              className={classes.form__fieldWithError}
              fullWidth
              InputLabelProps={{ shrink: true }}
              label='Descrição'
              margin='normal'
              name='description'
              variant="outlined"
              required
            />
          </div>
          <div className={classes.form__field__wrapper}>
            <Field
              component={Select}
              className={classes.form__fieldWithError}
              InputLabelProps={{ shrink: true, required: true }}
              label='Tipo de Assinatura'
              name='signatureType'
              margin='normal'
              fields={{ id: 'id', name: 'name' }}
              data={signatureTypes}
              fullWidth
              variant="outlined"
              required
            >
            </Field>
          </div>
          <div className={classes.form__field__wrapper}>
            <Field
              component={TextField}
              className={classes.form__fieldWithError}
              fullWidth
              InputLabelProps={{ shrink: true }}
              label='Pasta'
              margin='normal'
              name='folder'
              variant="outlined"
              required
            />
            <Alert variant="outlined" severity="info">
              Você pode utilizar qualquer campo personalizado utilizando &#123;&#123;nome_do_campo&#125;&#125;.<br/>
              Ex: /Alunos/&#123;&#123;grupo&#125;&#125;/125.43/
            </Alert>
          </div>
          <br />
          <FormCustomFields
            classes={classes}
            dataTypes={dataTypes}
            form={form}
          />
          <br />
          <FormSignatureSteps
            classes={classes}
            signerTypes={signerTypes}
            values={values}
            roles={roles}
            users={users}
            form={form}
          />
        </main>
        <footer className={classes.form__actions}>
          <Button
            className={classes.form__action}
            color='primary'
            type='submit'
            variant='contained'
          >
            {title}
          </Button>

          <Button
            className={classes.form__action}
            color='secondary'
            href={`/document_groups`}
          >
            Voltar
          </Button>
        </footer>
      </Paper>
    </form>
  )
}

export default withStyles(styles)(Form)
