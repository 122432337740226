import React from "react"
import { wrapper } from '@support/AppContainer'
import { withSnackbar } from 'notistack'

class Snackbar extends React.Component {
  componentWillMount() {
    this.props.messages.map(message => {
      this.props.enqueueSnackbar(message.title, { variant: message.type })
    })
  }

  render () {
    return <div style={{display: "none"}} />;
  }
}

export default wrapper(
  withSnackbar(Snackbar)
)
