import React from 'react'
import PropTypes from 'prop-types'
import { Form as FinalForm } from 'react-final-form'

import { validate } from '@models/user'
import { RailsForm } from '@support/RailsForm'
import { wrapper } from '@support/AppContainer'
import Form from './Form'

export const Edit = ({ model, roles }) => (
  <RailsForm
    component={FinalForm}
    action='update'
    url={`/users/${model.id}`}
    successUrl={`/users`}
    validate={validate}
    initialValues={model}
    render={(props) => (
      <Form
        {...props}
        title='Alterar Usuário'
        roles={roles}
      />
    )}
  />
)

Edit.propTypes = {
  model: PropTypes.object
}

export default wrapper(Edit)
