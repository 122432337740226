import React, { Component } from 'react';

import Button from '@material-ui/core/Button'
import InputLabel from '@material-ui/core/InputLabel'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import { Field } from 'react-final-form'
import Dropzone from 'react-dropzone'
import UploadIcon from '@material-ui/icons/Cloud'
import Select from '@ui/Fields/Select'
import TextField from '@ui/Fields/TextField'
import styles from '@theme/styles/forms'
import EduSigner from 'edu-signer-js';
import httpService from '@services/httpService'

const signer = new EduSigner({
  licenseKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJjZXJ0aWZpY2FlZHUuY29tLmJyIiwiZXhwIjozMjUwMzYwMDgwMH0.vqfFSV4mqs5aSggT59iiIF2tSkQvrIFq5FKMy6WORJk'
})

const pki = signer.PKI

class Form extends Component {
  constructor(props) {
    super(props)

    this.state = {
      certificates: [],
      currentPem: null
    }
  }

  componentDidMount() {
    pki.getCertificates().then(certs => this.setState({certificates: certs}))
  }

  render() {
    const { classes, title, values, certificateTypes } = this.props
    const { certificates, currentFile } = this.state

    const handleSubmit = (e) => {

      e.preventDefault()

      const { certificateType,  password, file, fingerprint } = values
      const formData = new FormData();

      formData.append('certificate[type]', certificateType)

      try{
        if (certificateType === 'A1'){
          formData.append('certificate[file]', file)
          formData.append('certificate[password]', password)
          httpService.post('/certificates', formData).then(result => Turbolinks.visit('/certificates'))
        } else {
          pki.getCertificate(fingerprint).then(cert => {
            formData.append('certificate[pem]', cert.base64)
            formData.append('certificate[fingerprint]', fingerprint)
            httpService.post('/certificates', formData).then(result => Turbolinks.visit('/certificates'))
          })
        }
      }
      catch (error) {
        console.error(error)
      }

      Turbolinks.visit('/certificates')
    }

    const onFileDrop = (files, rejectedFiles) => {
      if (rejectedFiles.length > 0) return

      const newFile = files[0]

      values['file'] = newFile
      this.setState({ currentFile: newFile.name })
    }

    const dropzoneClassName = ({ isDragActive, isDragReject }) => {
      if (isDragReject) {
        return classes.form__dropzone_onDragReject
      }
      if (isDragActive) {
        return classes.form__dropzone_onDrag
      }

      return null
    }

    return (
      <form className={classes.form} onSubmit={handleSubmit} data-testid="certificate-form">
        <Paper className={classes.form__wrapper} elevation={0}>
          <header className={classes.form__header}>
            <Typography component='h2' variant='h5'>
              {title}
            </Typography>
          </header>
          <main className={classes.form__fields}>
            <div className={classes.form__field__wrapper}>
              <Field
                component={Select}
                className={classes.form__fieldWithError}
                InputLabelProps={{ shrink: true, required: true }}
                label='Tipo do Certificado'
                name='certificateType'
                margin='normal'
                fields={{ id: 'id', name: 'name', key: 'id' }}
                data={certificateTypes}
                fullWidth
                variant="outlined"
                required
              >
              </Field>
            </div>
            {
              values.certificateType == 'A1' &&
              <div>
                <div className={classes.form__field__wrapper}>
                  <Field
                    component={TextField}
                    className={classes.form__fieldWithError}
                    InputLabelProps={{ shrink: true, required: true }}
                    label='Senha'
                    name='password'
                    margin='normal'
                    type="password"
                    fullWidth
                    variant="outlined"
                    required
                  >
                  </Field>
                </div>
                <div className={classes.form__field__wrapper}>
                  <InputLabel shrink>
                    Certificado pfx
                  </InputLabel>
                  <Dropzone
                    onDrop={onFileDrop}
                    multiple={false}
                  >
                    {({ getRootProps, getInputProps, isDragActive, isDragReject }) => {
                      const className = dropzoneClassName({ isDragActive, isDragReject })

                      return (
                        <div
                          variant='contained'
                          color='primary'
                          {...getRootProps()}
                          className={`${classes.form__dropzone} ${className}`}
                        >
                          <input {...getInputProps()} />
                          <UploadIcon />
                          <div>
                            {!isDragReject ?
                              'Arraste a imagem aqui, ou clique para adicionar' :
                              'Formato não suportado'}
                          </div>
                          {
                            currentFile &&
                            <Typography className={classes.form__dropzone_selectedFile}>
                              {`Selecionado: ${currentFile}`}
                            </Typography>
                          }
                        </div>
                      )
                    }}
                  </Dropzone>
                </div>
              </div>
            }
            { values.certificateType == 'A3' &&
              <div className={classes.form__field__wrapper}>
                <Field
                  component={Select}
                  className={classes.form__fieldWithError}
                  InputLabelProps={{ shrink: true, required: true }}
                  label='Selecione o Certificado'
                  name='fingerprint'
                  margin='normal'
                  fields={{ id: 'thumbprint', name: 'subject', key: 'thumbprint' }}
                  data={certificates}
                  fullWidth
                  variant="outlined"
                  required
                >
                </Field>
              </div>
            }
          </main>
          <footer className={classes.form__actions}>
            <Button
              className={classes.form__action}
              color='primary'
              type='submit'
              variant='contained'
            >
              {title}
            </Button>

            <Button
              className={classes.form__action}
              color='secondary'
              href={`/certificates`}
            >
              Voltar
            </Button>
          </footer>
        </Paper>
      </form>
    )
  }
}

export default withStyles(styles)(Form)
