import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import withMobileDialog from '@material-ui/core/withMobileDialog'

class PreviewDocument extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isDialogOpen: false,
      rejectionReason: ''
    }
  }

  render() {
    const { fullScreen, showSign, onClose, open, rowData, handleSign, handleReject } = this.props

    return (
      <Dialog fullScreen={fullScreen} open={open} onClose={onClose}>
        <DialogTitle>Exibir Documento</DialogTitle>
        <DialogContent>
          <iframe src={rowData && rowData.document.fileUrl} height="100%" width="100%" border="0">
          </iframe>
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            onClick={onClose}
          >
            Fechar
          </Button>
          <Button
            color='secondary'
            variant='contained'
            onClick={handleReject}
          >
            Rejeitar
          </Button>
          { showSign &&
            <Button
              color='primary'
              variant='contained'
              onClick={handleSign}
            >
              Assinar
            </Button>
          }
        </DialogActions>
      </Dialog>
    )
  }
}

export default withMobileDialog()(PreviewDocument)
