import PropTypes from 'prop-types'
import validator from 'validator'

export const validate = values => {
  const requiredFields = ['certificate_type']
  const requiredFieldsForA1 = ['file']

  const errors = {}

  requiredFields.forEach(field => {
    if (!values[field]) errors[field] = 'Campo Obrigatório'
  })

  if (values['certificate_type'] === '0') {
    requiredFieldsForA1.forEach(field => {
      if (!values[field]) errors[field] = 'Campo Obrigatório'
    })
  }

  return errors
}
