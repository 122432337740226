import React, { Component } from "react"
import styles from '@styles/indexPage'
import Paper from '@material-ui/core/Paper'
import Table from '@ui/Table'
import Chip from '@material-ui/core/Chip'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import ShowIcon from '@material-ui/icons/RemoveRedEye'
import PreviewDocument from '@ui/PreviewDocument'
import RejectDialog from '@ui/RejectDialog'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import httpService from '@services/httpService'
import { wrapper } from '@support/AppContainer'

class Index extends Component {

 constructor(props) {
    super(props)
    this.state = {
      isDialogOpen: false,
      isRejectDialogOpen: false,
      rejectionReason: '',
      rowData: null
    }
    this.closeDialog = this.closeDialog.bind(this)
    this.closeRejectDialog = this.closeRejectDialog.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.tableRef = React.createRef()
  }

  closeDialog() {
    this.setState({ isDialogOpen: false })
  }

  closeRejectDialog() {
    this.setState({ isRejectDialogOpen: false })
  }

  handleChange(event) {
    this.setState({ rejectionReason: event.target.value })
  }

  render () {
    const { model, classes } = this.props
    const {
      isDialogOpen,
      isRejectDialogOpen,
      rejectionReason,
      rowData
    } = this.state

    const handleResolve = (rows) => {
      httpService.post(`/signature_logs/resolve`, { ids: rows.map( (row) => row.id ) })
        .finally(() => location.reload() )
    }

    const handleReject = () => {
      httpService.post(`/signatures/${rowData.signatureId}/reject`, { rejection_reason: rejectionReason }).then((result)=>{
        httpService.post(`/signature_logs/resolve`, { ids: rowData.signatureId })
      }).finally(() => {
        this.setState({ isRejectDialogOpen: false, isDialogOpen: false })
        location.reload()
      })
    }

    return (
      <Paper className={classes.page__wrapper} elevation={0}>
        <header className={classes.page__header}>
          <Typography component='h1' variant='h4'>
            Logs
          </Typography>
        </header>

        <Table
          components={{
            Container: props => <div>{props.children}</div>,
          }}
          columns={[
            { title: 'Document', field: 'document.originalFilename' },
            { title: 'Usuário', field: 'userName' },
            { title: 'Mensagem', field: 'message' },
            { title: 'Ocorrências', field: 'count' },
            {
              title: 'Situação',
              field: 'status',
              lookup: { 'Pendente': 'Pendente', 'Resolvido': 'Resolvido' },
              render: rowData => <Chip label={rowData.status} key={rowData.status} />
            },
            {
              render: rowData =>
                <Tooltip title="Visualizar">
                  <IconButton
                    color="inherit"
                    onClick={(event) => this.setState({
                      isDialogOpen: true,
                      rowData: rowData
                    })}
                  >
                    <ShowIcon/>
                  </IconButton>
                </Tooltip>
            }
          ]}
          tableRef={this.tableRef}
          data={model}
          actions={[
            {
              icon: 'done',
              tooltip: 'Resolver',
              isFreeAction: false,
              onClick: (event, rows) => {
                handleResolve(rows)
              }
            }
          ]}
          options={{
            showTitle: false,
            selection: true,
            pageSize: 25,
            pageSizeOptions: [25, 50, 100],
            emptyRowsWhenPaging: false,
            sorting: false,
            filtering: true
          }}
        />
        <RejectDialog
          onClose={this.closeRejectDialog}
          open={isRejectDialogOpen}
          handleOk={() => handleReject() }
          handleChange={this.handleChange}
        />
        <PreviewDocument
          fullScreen={true}
          showSign={false}
          onClose={this.closeDialog}
          open={isDialogOpen}
          handleReject={() => this.setState({isRejectDialogOpen: true})}
          handleSign={() => handleSign(true)}
          rowData={rowData}
        />
      </Paper>
    )
  }
}

export default wrapper(
  withStyles(styles)(Index)
)
