export default () => ({
  container: {
    display: 'flex'
  },
  menu: {
    background: 'white',
    display: 'flex',
    justifyContent: 'space-between'
  },
  menu__logoLink: {
    textDecoration: 'none',
    width: 50,
    display: 'grid'
  },
  wrapper: {
    minHeight: '64px'
  }
});
