import React, { Component } from 'react';

import Button from '@material-ui/core/Button'
import InputLabel from '@material-ui/core/InputLabel'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import { Field } from 'react-final-form'
import Dropzone from 'react-dropzone'
import UploadIcon from '@material-ui/icons/Cloud'
import Select from '@ui/Fields/Select'
import styles from '@theme/styles/forms'
import httpService from '@services/httpService'

class Form extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentFile: null
    }
  }

  render() {
    const { classes, title, values, documentTypes } = this.props
    const { model, currentFile } = this.state

    const handleSubmit = (e) => {
      e.preventDefault()

      const { file, documentTypeId } = values
      const formData = new FormData();

      formData.append('documents_bulk_import[file]', file)
      formData.append('documents_bulk_import[document_type_id]', documentTypeId)

      httpService.post('/documents_bulk_imports', formData).then((result) => {
        Turbolinks.visit('/documents_bulk_imports')
      })
    }

    const onFileDrop = (files, rejectedFiles) => {
      if (rejectedFiles.length > 0) return

      const newFile = files[0]

      values['file'] = newFile
      this.setState({ currentFile: newFile.name })
    }

    const dropzoneClassName = ({ isDragActive, isDragReject }) => {
      if (isDragReject) {
        return classes.form__dropzone_onDragReject
      }
      if (isDragActive) {
        return classes.form__dropzone_onDrag
      }

      return null
    }

    return (
      <form className={classes.form} onSubmit={handleSubmit} data-testid="documents-bulk_import-form">
        <Paper className={classes.form__wrapper} elevation={0}>
          <header className={classes.form__header}>
            <Typography component='h2' variant='h5'>
              {title}
            </Typography>
          </header>
          <main className={classes.form__fields}>
            <div className={classes.form__field__wrapper}>
              <Field
                component={Select}
                className={classes.form__fieldWithError}
                InputLabelProps={{ shrink: true, required: true }}
                label='Tipo de documento'
                name='documentTypeId'
                margin='normal'
                fields={{ id: 'id', name: 'description' }}
                data={documentTypes}
                fullWidth
                variant="outlined"
                required
              >
              </Field>
            </div>
            <div className={classes.form__field__wrapper}>
              <Dropzone
                onDrop={onFileDrop}
                accept="application/pdf, application/zip"
                multiple={false}
              >
                {({ getRootProps, getInputProps, isDragActive, isDragReject }) => {
                  const className = dropzoneClassName({ isDragActive, isDragReject })

                  return (
                    <div
                      variant='contained'
                      color='primary'
                      {...getRootProps()}
                      className={`${classes.form__dropzone} ${className}`}
                    >
                      <input {...getInputProps()} />
                      <UploadIcon />
                      <div>
                        {!isDragReject ?
                          'Arraste um documento ou um arquivo .ZIP aqui, ou clique para adicionar' :
                          'Formato não suportado'}
                      </div>
                      {
                        currentFile &&
                        <Typography className={classes.form__dropzone_selectedFile}>
                          {`Selecionado: ${currentFile}`}
                        </Typography>
                      }
                    </div>
                  )
                }}
              </Dropzone>
            </div>
          </main>
          <footer className={classes.form__actions}>
            <Button
              className={classes.form__action}
              color='primary'
              type='submit'
              variant='contained'
            >
              {title}
            </Button>

            <Button
              className={classes.form__action}
              color='secondary'
              href={`/documents_bulk_imports`}
            >
              Voltar
            </Button>
          </footer>
        </Paper>
      </form>
    )
  }
}

export default withStyles(styles)(Form)
