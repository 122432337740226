import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import withMobileDialog from '@material-ui/core/withMobileDialog'

import httpService from '@services/httpService'

class DeleteDialog extends Component {
  constructor(props) {
    super(props)

    this.defaultHandleDelete = this.defaultHandleDelete.bind(this)
  }

  defaultHandleDelete() {
    const {
      deleteUrl,
      successUrl
    } = this.props

    httpService
      .delete(deleteUrl)
      .then(() =>
        Turbolinks.visit(successUrl)
      )
      .catch(() =>
        console.error('boom!', error)
      )
  }

  render() {
    const { fullScreen, onCancel, onClose, open, dialogTitle, dialogText, handleDelete, actionText } = this.props

    const handler = handleDelete || this.defaultHandleDelete

    return (
      <Dialog fullScreen={fullScreen} open={open} onClose={onClose}>
        <DialogTitle>{dialogTitle}</DialogTitle>

        <DialogContent>
          <DialogContentText>
            {dialogText}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            variant='outlined'
            onClick={onCancel}
          >
            Cancelar
          </Button>

          <Button
            color='primary'
            variant='contained'
            onClick={handler}
          >
            Excluir
          </Button>

        </DialogActions>
      </Dialog>
    )
  }
}

export default withMobileDialog()(DeleteDialog)
