import React from 'react'
import { Form as FinalForm } from 'react-final-form'
import PropTypes from 'prop-types'

import { validate } from '@models/user'
import { RailsForm } from '@support/RailsForm'
import { wrapper } from '@support/AppContainer'
import Form from './Form'

export const New = ({ model, roles }) => {
  return (
    <RailsForm
      component={FinalForm}
      action='create'
      url='/users'
      successUrl='/users'
      validate={validate}
      initialValues={model}
      render={(props) => (
        <Form
          {...props}
          title='Criar Usuário'
          roles={roles}
        />
      )}
    />
  )
}

New.propTypes = {
  model: PropTypes.object
}

export default wrapper(New)
