import React from 'react'

import withWidth from '@material-ui/core/withWidth'
import LogoUniube from '../../../../../assets/images/logo-uniube.png'
import LogoImage from '../../../../../assets/images/logo.png'
import Icon from '../../../../../assets/images/logo_minimized.png'

const Logo = props => {
  const { width, organization } = props
  const components = {
    xs: Icon,
    sm: Icon,
    md: organization.id === "28d3f35a-e416-472f-a7e0-7f8eac9d6adc" ? LogoUniube : LogoImage,
    lg: organization.id === "28d3f35a-e416-472f-a7e0-7f8eac9d6adc" ? LogoUniube : LogoImage,
  }

  const Component = components[width] || (organization.id === "28d3f35a-e416-472f-a7e0-7f8eac9d6adc" ? LogoUniube : LogoImage)

  return (<img src={Component}/>)
}

export default withWidth()(Logo)
