import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

import { wrapper } from '@support/AppContainer'
import DeleteDialog from '@ui/DeleteDialog'
import styles from '@styles/showPage'

export class Show extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isDialogOpen: false
    }

    this.toggleDialog = this.toggleDialog.bind(this)
  }

  toggleDialog() {
    this.setState({ isDialogOpen: !this.state.isDialogOpen })
  }

  render() {
    const {
      classes,
      model
    } = this.props

    const { isDialogOpen } = this.state

    return (
      <Paper
        className={classes.showPage__wrapper}
        elevation={0}
      >
        <header className={classes.showPage__header}>
          <Typography component='h2' variant='h5'>
            Usuário
          </Typography>
        </header>

        <main className={classes.showPage__values}>
          <div className={classes.showPage__value}>
            <Typography
              color='textSecondary'
              component='h6'
              gutterBottom
              variant='h6'
            >
              Email
            </Typography>

            <Typography paragraph variant='subtitle1' data-testid='email'>
              {model.email}
            </Typography>
          </div>

          <div className={classes.showPage__value}>
            <Typography
              color='textSecondary'
              component='h6'
              gutterBottom
              variant='h6'
            >
              Nome
            </Typography>

            <Typography variant='subtitle1' paragraph data-testid='name'>
              {model.name}
            </Typography>
          </div>
        </main>

        <footer className={classes.showPage__actions}>
          <Button
            className={classes.showPage__action}
            color='primary'
            variant='contained'
            href={`/users/${model.id}/edit`}
          >
            Editar
          </Button>

          <Button
            className={classes.showPage__action}
            color='secondary'
            variant="contained"
            onClick={this.toggleDialog}
          >
            Excluir
          </Button>
          <Button
            className={classes.showPage__action}
            color='secondary'
            href={`/users`}
          >
            Voltar
          </Button>
        </footer>

        <DeleteDialog
          dialogTitle={'Remover Usuário'}
          dialogText={'Deseja remover este usuário?'}
          successUrl='/users'
          deleteUrl={`/users/${model.id}.json`}
          onCancel={this.toggleDialog}
          onClose={this.toggleDialog}
          open={isDialogOpen}
        />
      </Paper>
    )
  }
}

Show.propTypes = {
  model: PropTypes.object
}

export default wrapper(
  withStyles(styles)(Show)
)
