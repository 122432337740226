import React, { Component } from "react"
import PropTypes from "prop-types"
import Table from '@ui/Table'
import Paper from '@material-ui/core/Paper'
import LinearProgress from '@material-ui/core/LinearProgress'
import { PromisePool } from 'es6-promise-pool'
import Select from '@ui/NativeSelect'
import { Field } from 'react-final-form'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton'
import ShowIcon from '@material-ui/icons/RemoveRedEye'
import PreviewDocument from '@ui/PreviewDocument'
import PasswordDialog from '@ui/PasswordDialog'
import RejectDialog from '@ui/RejectDialog'
import { wrapper } from '@support/AppContainer'
import withStyles from '@material-ui/core/styles/withStyles'
import httpService from '@services/httpService'
import styles from '@styles/indexPage'
import { withSnackbar } from 'notistack'
import EduSigner from 'edu-signer-js'
import { pki, signDocument, errors } from '@models/signature'

class Index extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isDialogOpen: false,
      isPasswordDialogOpen: false,
      isRejectDialogOpen: false,
      inProgress: false,
      individual: false,
      progress: 0,
      rowData: null,
      certificate: null,
      password: '',
      rejectionReason: '',
    }

    this.closeDialog = this.closeDialog.bind(this)
    this.closePasswordDialog = this.closePasswordDialog.bind(this)
    this.closeRejectDialog = this.closeRejectDialog.bind(this)
    this.tableRef = React.createRef()
  }

  closeDialog() {
    this.setState({ isDialogOpen: false })
  }

  closePasswordDialog() {
    this.setState({ isPasswordDialogOpen: false })
  }

  closeRejectDialog() {
    this.setState({ isRejectDialogOpen: false })
  }

  componentWillMount() {
    if (this.props.certificates.length > 0)
      this.setState({ certificate: this.props.certificates[0] })
  }

  render () {
    const { model, classes, certificates, enqueueSnackbar } = this.props
    const {
      isDialogOpen,
      isPasswordDialogOpen,
      isRejectDialogOpen,
      fileUrl,
      password,
      certificate,
      inProgress,
      individual,
      rejectionReason,
      progress,
      rowData
    } = this.state

    const startSign = () => {
      individual && individualSign() || bulkSign()
    }

    const individualSign = () => {
      signDocument(rowData, certificate, password).then(() => {
        enqueueSnackbar('Assinaturas concluídas com sucesso!', { variant: 'success' })
      }).catch( error => {
        enqueueSnackbar('Uma ou mais assinaturas falharam!', { variant: 'error' })
      }).finally(() => {
        this.setState({ progress: 0, inProgress: false, isDialogOpen: false })
        this.tableRef.current.onQueryChange()
      })
      return true
    }

    const bulkSign = () => {
      let selectedRows = _.filter(this.tableRef.current.state.data, o => o.tableData.checked)
      let count = 0
      let promiseProducer = () => {
        if (count < selectedRows.length) {
          count++;
          this.setState({ progress: (count / selectedRows.length) * 100 })
          return signDocument(selectedRows[count - 1], certificate, password)
        } else {
          return null;
        }
      }
      let handleError = function(e) { return e }
      let concurrency = 5
      let pool = new PromisePool(promiseProducer, concurrency)
      let poolPromise = pool.start()

      poolPromise.then((result) => {
        errors.forEach((error) =>{
          enqueueSnackbar(`${error[0].document.originalFilename} falhou!`, { variant: 'error' })
        })
        enqueueSnackbar('Assinaturas concluídas!', { variant: 'success' })
      }).catch( error => {
        enqueueSnackbar('Ocorreu um erro, tente novamente!', { variant: 'error' })
      }).finally(() => {
        errors.length = 0
        this.setState({ progress: 0, inProgress: false, isDialogOpen: false })
        this.tableRef.current.onQueryChange()
      })
    }

    const handleA1 = () => {
      httpService.post(`/certificates/${certificate.id}/validate_password`, { password: password }).then((result)=>{
        if (result.data.valid) {
          this.setState({ isPasswordDialogOpen: false, inProgress: true })
          startSign()
        } else {
          this.props.enqueueSnackbar('PIN inválido', { variant: 'error' })
        }
      })
    }

    const handleReject = () => {
      httpService.post(`/signatures/${rowData.id}/reject`, { rejection_reason: rejectionReason }).then((result)=>{
        enqueueSnackbar('Documento rejeitado com sucesso!', { variant: 'success' })
      }).catch( error => {
        console.log(error.message)
        enqueueSnackbar('Falha ao rejeitar document!', { variant: 'error' })
      }).finally(() => {
        this.setState({ isRejectDialogOpen: false, isDialogOpen: false })
        this.tableRef.current.onQueryChange()
      })
    }

    const handleCertificate = event => {
      let certificate = certificates.find((e) => e.id === event.target.value)
      this.setState({ certificate: certificate })
    }

    const handlePassword = event => {
      this.setState({ password: event.target.value })
    }

    const handleReason = event => {
      this.setState({ rejectionReason: event.target.value })
    }

    const handleSign = (individual) => {
      if (certificate.certificateType == 'A1'){
        this.setState({ isPasswordDialogOpen: true, individual: individual })
      } else {
        this.setState({ inProgress: true, individual: individual })
        startSign()
      }
    }

    return (
      <Paper className={classes.page__wrapper} elevation={0}>
        <header className={classes.page__header}>
          <Typography component='h1' variant='h4'>
            Documentos a assinar
          </Typography>
          <Select
            data={certificates}
            fields={{ id: 'id', name: 'subject' }}
            name='certificateId'
            label='Selecione o Certificado'
            value={certificate && certificate.id || ''}
            handleChange={handleCertificate}
          />
        </header>
        {
          inProgress &&
            <LinearProgress variant="determinate" value={progress} />
        }
        <Table
          components={{
            Container: props => <div>{props.children}</div>,
          }}
          detailPanel={rowData => {
            return ( <pre>{ JSON.stringify(rowData.document.metadata, null, '\t') }</pre> )
          }}
          columns={[
            { title: 'Data', field: 'createdAt' },
            { title: 'Descrição', field: 'document.documentType.description' },
            { title: 'Arquivo', field: 'document.originalFilename' },
            {
              render: rowData =>
                <Tooltip title="Visualizar">
                  <IconButton
                    color="inherit"
                    onClick={(event) => this.setState({
                      isDialogOpen: true,
                      rowData: rowData
                    })}
                  >
                    <ShowIcon/>
                  </IconButton>
                </Tooltip>
            }
          ]}
          tableRef={this.tableRef}
          data={
            query =>
              new Promise((resolve, reject) => {
                httpService.get(`/signatures.json?search=${query.search}&per_page=${query.pageSize}&page=${query.page + 1}`)
                  .then((result) => result.data)
                  .then((result) => {
                    resolve({
                      data: result.data,
                      page: query.page,
                      totalCount: result.total
                    })
                  })
              })
          }
          actions={[
            {
              icon: 'done',
              tooltip: 'Assinar',
              isFreeAction: false,
              onClick: (event, rows) => {
                handleSign(false)
              }
            },
            {
              icon: 'refresh',
              tooltip: 'Atualizar',
              isFreeAction: true,
              onClick: () => this.tableRef.current && this.tableRef.current.onQueryChange(),
            }
          ]}
          options={{
            showTitle: false,
            selection: true,
            pageSize: 25,
            pageSizeOptions: [25, 50, 100, 500, 1000],
            emptyRowsWhenPaging: false,
            debounceInterval: 500,
            sorting: false
          }}
        />
        <PasswordDialog
          onClose={this.closePasswordDialog}
          open={isPasswordDialogOpen}
          handleOk={() => handleA1()}
          password={password}
          certificate={certificate}
          handleChange={handlePassword}
        />
        <RejectDialog
          onClose={this.closeRejectDialog}
          open={isRejectDialogOpen}
          handleOk={() => handleReject() }
          rejectionReason={rejectionReason}
          handleChange={handleReason}
        />
        <PreviewDocument
          fullScreen={true}
          showSign={true}
          onClose={this.closeDialog}
          open={isDialogOpen}
          handleReject={() => this.setState({isRejectDialogOpen: true})}
          handleSign={() => handleSign(true)}
          rowData={rowData}
        >
        </PreviewDocument>
      </Paper>
    )
  }
}

export default wrapper(
  withSnackbar(
    withStyles(styles)(Index)
  )
)
