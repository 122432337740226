export default theme => ({
  button: {
    margin: theme.spacing.unit
  },
  paper: {
    margin: theme.spacing(2),
    padding: theme.spacing(3)
  },
  gridPaper: {
    padding: theme.spacing(2)
  }
})
