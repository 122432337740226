import React from 'react'

import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Popover from '@material-ui/core/Popover'
import ProfileIcon from '@material-ui/icons/PermIdentity'
import withStyles from '@material-ui/core/styles/withStyles'
import OrganizationsList from './OrganizationsList'
import SelectedOrganization from './SelectedOrganization'
import CurrentUser from './CurrentUser'

import styles from './styles'

class OrganizationsMenu extends React.Component {
  state = {
    anchorEl: null
  }

  handleClick = event => {
    this.setState({
      anchorEl: event.currentTarget,
    })
  }

  handleClose = () => {
    this.setState({
      anchorEl: null,
    })
  }

  render() {
    const { classes, organizations, selectedOrganization, isAdmin, user } = this.props
    const { anchorEl } = this.state
    const open = Boolean(anchorEl)

    const logoutLink = '/users/sign_out'

    return (
      <div>
        <IconButton
          aria-owns={open ? 'render-props-popover' : null}
          aria-haspopup="true"
          variant="contained"
          name="organization-menu"
          onClick={this.handleClick}
        >
          <ProfileIcon/>
        </IconButton>

        <Popover
          id="simple-popper"
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <List className={classes.organizationsMenu__list}>
            <CurrentUser
              user={user}
            />
            <SelectedOrganization
              selected={selectedOrganization}
            />
            <OrganizationsList
              organizations={organizations}
            />
            <ListItem
              className={classes.organizationsMenu__footer}
            >
              <Button
                variant="outlined"
                className={classes.organizationsMenu__button}
                onClick={() => Turbolinks.visit(logoutLink)}
                data-testid="btnLogout"
              >
                Sair
              </Button>
            </ListItem>
          </List>
        </Popover>
      </div>
    )
  }
}

export default withStyles(styles)(OrganizationsMenu)
