import axios from 'axios';

const getToken = () => document.querySelector("meta[name=csrf-token]");

const httpService = axios.create({
  headers: {
    Accept: 'application/json, text/javascript',
    'X-CSRF-Token': (getToken() ? getToken().content : null)
  }
});

export default httpService;
