import React from 'react'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import { Field } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'
import httpService from '@services/httpService'
import Select from '@ui/Fields/Select'
import TextField from '@ui/Fields/TextField'
import styles from '@theme/styles/forms'
import FormCustomFields from './FormCustomFields'
import FormSignatureSteps from './FormSignatureSteps'

const getDocumentGroup = async (guid) => {
  try {
    const result = await httpService.get(`/document_groups/${guid}.json`)
    return result.data
  } catch(error) {

  }
}

const Form = ({
  handleSubmit,
  classes,
  title,
  values,
  signatureTypes,
  dataTypes,
  signerTypes,
  roles,
  users,
  groups,
  form
}) => {
  return (
    <form className={classes.form} onSubmit={handleSubmit} data-testid="document-type-form">
      <OnChange name="documentGroupId">
        { async (value, previous) => {
          if (value) {
            const documentGroup = await getDocumentGroup(value)
            form.reset({ ...values, customFields: [], signatureSteps: [], signatureType: documentGroup.signature_type })
            documentGroup.custom_fields.forEach( item => form.mutators.push('customFields', item) )
            documentGroup.signature_steps.forEach(
              item => form.mutators.push('signatureSteps', { ...item, icpBrasil: item.icp_brasil, signerType: item.signer_type, timeStamp: item.time_stamp })
            )
          }
        }}
      </OnChange>
      <Paper className={classes.form__wrapper} elevation={0}>
        <header className={classes.form__header}>
          <Typography component='h2' variant='h5'>
            {title}
          </Typography>
        </header>
        <main className={classes.form__fields}>
          <div className={classes.form__field__wrapper}>
            <Field
              component={Select}
              className={classes.form__fieldWithError}
              InputLabelProps={{ shrink: true, required: false }}
              label='Grupo de Documento'
              name='documentGroupId'
              margin='normal'
              fields={{ id: 'id', name: 'description' }}
              data={groups}
              fullWidth
              variant="outlined"
            >
            </Field>
          </div>
          <div className={classes.form__field__wrapper}>
            <Field
              component={TextField}
              className={classes.form__fieldWithError}
              margin='normal'
              fullWidth
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              label='Código'
              name='code'
              required
            />
          </div>
          <div className={classes.form__field__wrapper}>
            <Field
              component={TextField}
              className={classes.form__fieldWithError}
              fullWidth
              InputLabelProps={{ shrink: true }}
              label='Descrição'
              margin='normal'
              name='description'
              variant="outlined"
              required
            />
          </div>
          <div className={classes.form__field__wrapper}>
            <Field
              component={Select}
              className={classes.form__fieldWithError}
              InputLabelProps={{ shrink: true, required: true }}
              label='Tipo de Assinatura'
              name='signatureType'
              margin='normal'
              fields={{ id: 'id', name: 'name' }}
              data={signatureTypes}
              fullWidth
              variant="outlined"
              required
            >
            </Field>
          </div>
          <FormCustomFields
            classes={classes}
            dataTypes={dataTypes}
            form={form}
          />
          <br />
          <FormSignatureSteps
            classes={classes}
            signerTypes={signerTypes}
            values={values}
            roles={roles}
            users={users}
            form={form}
          />
        </main>
        <footer className={classes.form__actions}>
          <Button
            className={classes.form__action}
            color='primary'
            type='submit'
            variant='contained'
          >
            {title}
          </Button>

          <Button
            className={classes.form__action}
            color='secondary'
            href={`/document_types`}
          >
            Voltar
          </Button>
        </footer>
      </Paper>
    </form>
  )
}

export default withStyles(styles)(Form)
