import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Input from '@material-ui/core/Input';
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import withMobileDialog from '@material-ui/core/withMobileDialog'

class RejectDialog extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      onClose,
      open,
      rejectionReason,
      handleChange,
      handleOk
    } = this.props

    return (
      <Dialog open={open} onClose={onClose} fullWidth={true}>
        <DialogTitle>Informe o motivo</DialogTitle>
        <DialogContent>
          <Input
            id="rejectionReason"
            value={rejectionReason}
            onChange={handleChange}
            fullWidth={true}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            onClick={onClose}
          >
            Cancelar
          </Button>
          <Button
            color='primary'
            variant='contained'
            onClick={handleOk}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
export default withMobileDialog()(RejectDialog)
