import { createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#474aa5'
    },
    secondary: {
      main: '#f77c22',
      contrastText: '#fff'
    },
    error: red,
    gray: '#767676',
    white: '#fff',
    lightGray: '#f5f5f5'
  },
  typography: {
    useNextVariants: true,
  }
})

export default theme
